import React from "react";
import { useForm } from "react-hook-form";

import { Button, Input, WLink, Text, Container, Layout } from "components";

const Overview = () => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    alert(`Hi ${data.name}`);

    setTimeout(() => {
      resetField("name");
    }, 2000);
  };

  return (
    <Layout title="Overview">
      <Container>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-40 bg-primary p-7 lg:flex gap-4"
        >
          <Input
            required
            type="text"
            register={register("name")}
            error={errors.name?.message}
            placeholder="Enter your name"
          />
          <Button variant="secondary" text="Submit" />
        </form>

        <div className="mt-10">
          <Text
            variant="h2"
            value="Gotcha! Kindly check the README.md for more details"
          />
        </div>

        <div className="my-10">
          <WLink to="/get-lost">Click here to get lost 😉</WLink>
        </div>
      </Container>
    </Layout>
  );
};

export default Overview;
